import API_ENDPOINT from "../../api/api_endpoint";
import { getApi } from "../../api/api_methods";
import NotificationComponent from "../../components/alert-component/component";
import { loadNewsDetails, loadNewsList } from "./news.slice";

export function loadNewsListAPI() {
	return async (dispatch) => {
		getApi(API_ENDPOINT.LOAD_NEWS)
			.then((res) => {
				let response = res.data ? res.data : [];
				dispatch(loadNewsList(response));
			})
			.catch((err) => {
				NotificationComponent(
					false,
					"error",
					"Erreur de chargement des actualités"
				);
			});
	};
}

export function loadNewsDetailAPI(slug) {
	return async (dispatch) => {
		getApi(API_ENDPOINT.LOAD_NEWS + "/" + slug)
			.then((res) => {
				let response = res.data ? res.data : [];
				dispatch(loadNewsDetails(response));
			})
			.catch((err) => {
				NotificationComponent(
					false,
					"error",
					"Erreur de chargement de l'actualité"
				);
			});
	};
}
