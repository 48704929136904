import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { loadPropsDetailsUtil, updateFilterUtil } from "./index.util";

const initialState = {
    propsList: [],
    propsArea: [],
    propsCateg: [],
    filterForm_SearchList: {
        min: 0,
        max: 600000
    },
    propDetails: {}
};
const properties = createSlice({
    name: "properties",
    initialState,
    reducers: {
        loadPropsList: (state, { payload }) => {
            state.propsList = payload;
        },
        loadPropsArea: (state, { payload }) => {
            state.propsArea = payload;
        },
        loadPropsCateg: (state, { payload }) => {
            state.propsCateg = payload;
        },
        updateFilter: (state, { payload }) => {
            let clonedFiche = _.cloneDeep(state.filterForm_SearchList);
            state.filterForm_SearchList = updateFilterUtil(clonedFiche, payload);
        },
        initFilter: (state, { payload }) => {
            state.filterForm_SearchList = initialState.filterForm_SearchList
        },
        loadPropsDetails: (state, { payload }) => {
            state.propDetails = loadPropsDetailsUtil(payload);
        }
    },
});

export const { loadPropsList, loadPropsArea, loadPropsCateg, updateFilter, initFilter, loadPropsDetails } = properties.actions;

export default properties.reducer;
