import React, { Component } from 'react';
import sectiondata from '../../data/notre-agence.json';
import parse from 'html-react-parser';

class Mission extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL
        let imagealt = 'image'
        let data = sectiondata.mission


        return <div className="mission-vission-area pd-top-100 pd-bottom-70 bg-gray">
            <div className="container">
                <div className="row justify-content-center">
                    { data.items.map( ( item, i )=>
                        <div key={ i } className={ "missions col-xl-3 col-lg-4 col-sm-6 mb-3 "+item.class }>
                            <div className="single-intro text-lg-left">
                                <div className="thumb">
                                    <img className="thumb-icon" src={ publicUrl+item.image } alt={ imagealt } />
                                </div>
                                <div className="details">
                                    <h4 className="title">{ item.title }</h4>
                                    <p>{ item.content }</p>
                                </div>
                            </div>
                        </div>
                    ) }

                </div>
            </div>
        </div>


    }
}

export default Mission