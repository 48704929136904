import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';

import ServicesAcheteursTitle from "./section-components/services-acheteurs-title";
import ServicesAcheteursPart1 from "./section-components/services-acheteurs-part1";
import ServicesAcheteursPart2 from "./section-components/services-acheteurs-part2";
import ContactSection from "./section-components/contact-section";

import Footer from './global-components/footer';

const Acheteurs = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Acheteurs" bgimage="assets/images/bandeaux/agence-valucian-cession-commerces-02.jpg" bgalign="center center" />
        <ServicesAcheteursTitle />
        <ServicesAcheteursPart1 />
        <ServicesAcheteursPart2 />
        <ContactSection />
        <Footer />
    </div>
}

export default Acheteurs

