const API_ENDPOINT = {
	/*
	 ** GLOBAL **
	 */
	SEND_MAIL: "/sendmail",
	LOAD_VERSION: "api-neelaps.config",
	LOAD_NAV_FAM: "/web/navigation",
	/*
	 ** PROPS **
	 */
	LOAD_PROPS: "/web/properties",
	LOAD_PROPS_AREA: "/web/properties-area",
	LOAD_PROPS_CATEG: "/web/properties-categories",
	/*
	 ** PARTNERS **
	 */
	LOAD_PARTNERS: "/web/partners",
	/*
	 ** NEWS **
	 */
	LOAD_NEWS: "/web/news",
};

export default API_ENDPOINT;
