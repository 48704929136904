/* -----------Components------------ */
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import Accompagnement from "./section-components/accompagnement";
import AcheteurVendeur from "./section-components/acheteur-vendeur";
import Banner from "./section-components/banner";
import FeaturedProperties from "./section-components/featured_prop/featured-properties";
import OurPartner from "./section-components/partners/partner";
/* -----------Assets------------ */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadPropsAPI } from "../redux/properties/index.api";
import LatestNews from "./news-components/latest-news";
/* -----------Style------------ */

const Home = () => {
	/* -----------VARS------------ */
	const dispatch = useDispatch();
	/* -----------Effect Hook------------ */
	useEffect(() => {
		dispatch(loadPropsAPI({ section: "homepage" }));
	}, []);
	/* -----------Assets------------ */

	return (
		<div>
			<Navbar />
			<Banner />
			<FeaturedProperties />
			<Accompagnement />
			<AcheteurVendeur />
			<LatestNews />
			<OurPartner />
			<Footer />
		</div>
	);
};

export default Home;
