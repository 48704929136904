import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';

import ServicesVendeursTitle from "./section-components/services-vendeurs-title";
import ServicesVendeursPart1 from "./section-components/services-vendeurs-part1";
import ServicesVendeursPart2 from "./section-components/services-vendeurs-part2";
import ContactSection from "./section-components/contact-section";

import Footer from './global-components/footer';

const Vendeurs = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Vendeurs" bgimage="assets/images/bandeaux/agence-valucian-cession-commerces-02.jpg" bgalign="center center" />
        <ServicesVendeursTitle />
        <ServicesVendeursPart1 />
        <ServicesVendeursPart2 />
        <ContactSection />
        <Footer />
    </div>
}

export default Vendeurs

