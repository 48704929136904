import React from "react";
import { useSelector } from "react-redux";
import { NewsDetailSelector } from "../redux/news/news.selector";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import NewsDetailsSection from "./news-components/news-details";

const NewsDetailsLayout = () => {
	const newsDetail = useSelector(NewsDetailSelector);

	return (
		<div>
			<Navbar />
			<PageHeader
				subheader={newsDetail.Titre_fr}
				subLink="actualites"
				subLinkText="Actualités"
				bgimage={newsDetail.mediaSrc}
				defaultBgImage={"/assets/img/news/img-placeholder.jpg"}
			/>
			<NewsDetailsSection />
			<Footer />
		</div>
	);
};

export default NewsDetailsLayout;
