import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { generateShareLink } from "../../data/data.utils";
import newsData from "../../data/news.json";
import socials from "../../data/social.json";
import { loadNewsDetailAPI } from "../../redux/news/news.api";
import { NewsDetailSelector } from "../../redux/news/news.selector";
import { formatDate } from "../../utils/date.utils";

const NewsDetailsSection = () => {
	let data = newsData.newsDetails;
	const newsDetail = useSelector(NewsDetailSelector);
	const { slug } = useParams();
	const dispatch = useDispatch();
	const DEFAULT_POST_MESSAGE = "";

	// We scroll to the top of the page when the component is mounted
	// else it would keep the scroll position of the page before
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		dispatch(loadNewsDetailAPI(slug));
	}, [slug]);

	return (
		<div>
			<div className="news-details-area">
				<div className="container mb-5">
					<div className="news-details-author-social">
						<div className="row">
							<div className="col-sm-6 mb-4 mg-sm-0">
								<div className="author mt-5 ml-5">
									<p>{data.writtenBy} {newsDetail.Author}</p>
									<p>-</p>
									<p>
										{" "}
										{data.publishedOn} {formatDate(newsDetail.DatePublication)}
									</p>
								</div>
							</div>
							<div className="col-sm-6">
								<ul className="social-icon style-two text-sm-right">
									{socials.map((social, i) => (
										<li key={i}>
											<a
												className={social.class}
												href={
													generateShareLink(
														window.location
															.href,
														DEFAULT_POST_MESSAGE
													)[social.name]
												}
												target="_blank"
												rel="noreferrer"
											>
												<i className={social.icon} />
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
					<div className="row justify-content-center pd-top-70">
						<div className="col-lg-8">
							<div className="news-details-wrap">
								<h3 className="title1">
									{newsDetail.Titre_fr}
								</h3>
								<div
									dangerouslySetInnerHTML={{
										__html: newsDetail.News_fr,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsDetailsSection;
