import axios from 'axios';
import { env } from '../environnement';

axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers["token"] = process.env.REACT_APP_NEELAPS_API_KEY.toString();

let api = () => {

    return axios.create({

        baseURL: env.NEELAPS_API_URL,
        headers: {
            Accept: "application/json, */*",
        },
        credentials: "same-origin"

    })

};

export default api
