import React, { Component } from 'react';
import sectiondata from '../../data/repreneurs.json';
import parse from 'html-react-parser';
import {Link} from "react-router-dom";

class ServicesAcheteursPart1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL
        let imagealt = 'image'
        let data = sectiondata.repreneurs.item_1

        return <div className="services-repreneurs pd-top-60 pd-bottom-60">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-1 order-lg-12 mb-4 mb-lg-0">
                        <div className="shape-image-list-wrap">
                            <div className="shape-image-list right-top">
                                <img className="shadow-img" src={ publicUrl+data.image } alt={ imagealt } />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 align-self-center order-lg-1">
                        <div>
                            { data.items.map( ( item, i )=>
                                <div className="mb-4">
                                    <h4 className="title inner-title"><i className={item.icon } />{ item.title }</h4>
                                    <p>{ item.content }</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ServicesAcheteursPart1