import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import newsData from "../../data/news.json";
import { loadNewsListAPI } from "../../redux/news/news.api";
import { NewsListSelector } from "../../redux/news/news.selector";
import "./latest-news.css";

const LatestNews = () => {
	let data = newsData.latestNews;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const latestNewsList = useSelector(NewsListSelector);

	useEffect(() => {
		dispatch(loadNewsListAPI());
	}, []);

	const navigateToNews = (slug) => {
		navigate("/actualites/" + slug);
	};

	if (latestNewsList.length === 0) {
		return null;
	} else {
		return (
			<div className="news-area pd-top-55">
				<div className="container">
					<div className="section-title text-center">
						<h2 className="title">{data.title}</h2>
					</div>
					<div className="row justify-content-center">
						{latestNewsList.slice(0, 3).map((news, i) => (
							<div
								key={i}
								className="col-xl-4 col-lg-4 col-md-6 col-sm-6"
							>
								<div style={{ position: "relative" }}>
									<div className="single-feature">
										<div className="thumb">
											<img
												className="mw-100"
												src={
													news.mediaSrc ??
													"assets/img/news/img-placeholder.jpg"
												}
												onError={(e) =>
													(e.target.src =
														"assets/img/news/img-placeholder.jpg")
												}
												alt={news.mediaAlt}
												onClick={() =>
													navigateToNews(news.Slug)
												}
											/>
										</div>
										<div className="details">
											<p className="author">
												<div className="d-flex flex-row align-items-baseline">
													<i className="fa fa-user pr-2" />
													<p className="mb-0">
														{news.Author}
													</p>
												</div>
												<div className="d-flex flex-row align-items-baseline">
													<i className="fa fa-calendar pr-2" />
													<p className="mb-0">
														{
															news.DatePublication.split(
																" "
															)[0]
														}
													</p>
												</div>
											</p>
											<h5
												className="title readeal-top"
												title={news.Titre_fr}
											>
												<Link
													to={`/actualites/${news.Slug}`}
												>
													{news.Titre_fr}
												</Link>
											</h5>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="mb-5 w-100 d-flex justify-content-center">
						<Link className="btn btn-orange" to="actualites">
							{data.moreNews}
						</Link>
					</div>
				</div>
			</div>
		);
	}
};

export default LatestNews;
