/* -----------Assets------------ */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import SearchList from './section-components/search_list/search-list';
import SearchGrid from "./section-components/search_grid/search-grid";
import Footer from './global-components/footer';
import { loadPropsAPI, loadPropsAreaAPI, loadPropsCategAPI } from '../redux/properties/index.api';
import { initFilter } from '../redux/properties/index.slice';
//import LoaderComponent from './loader-component';
/* -----------Selectors------------ */
import { PropListLoaderSelector, filterFormSearchListSelector } from '../redux/properties/index.selector';
import { navbarFamSelectedSelector } from '../redux/referentiel/index.selector';
/* -----------Style Sheet------------ */

const SectionCommerces = () => {

    /* -----------Vars------------ */
    const dispatch = useDispatch();
    const navbarSelectedFam = useSelector(navbarFamSelectedSelector);
    const filterForm = useSelector(filterFormSearchListSelector);
    /* -----------Effect Hook------------ */
    useEffect(() => {
        dispatch(loadPropsAreaAPI({}));
        dispatch(loadPropsCategAPI({}))
        /*dispatch(initFilter())*/
    }, []);
    useEffect(() => {
        dispatch(loadPropsAPI({ ...filterForm, ...navbarSelectedFam, section: 'search' }));
        dispatch(initFilter())
        }, [navbarSelectedFam]);
    return (
        <div>
            {/*loader ? <LoaderComponent /> : ""*/}
            <Navbar />
            <PageHeader
                headertitle="Nos biens à la vente"
                subheadertitle="Valucian votre partenaire en cession de fonds de commerces"
                subheader="Biens à vendre"
                bgimage="assets/images/pages/valucian-homepage-street.jpg"
                bgalign="center top"
            />
            <SearchGrid />
            <Footer />
        </div>
    )
};

export default SectionCommerces