import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import ContactSection from "./section-components/contact-section";

const Contact = () => {
    return <div>
        <Navbar />
		<PageHeader headertitle="Contact" bgimage="assets/images/bandeaux/agence-valucian-cession-commerces-02.jpg" bgalign="center center" />
		<ContactSection />
        <Footer />
    </div>
}

export default Contact

