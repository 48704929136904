/* -----------Assets------------ */
import React, { Component } from 'react';
import Empty from 'antd/lib/empty';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import placeholder_prop from "../../../assets/images/placeholder_prop.jpeg";
import { useDispatch } from "react-redux";
import { updateFilter } from '../../../redux/properties/index.slice';
import Slider from "antd/lib/slider"
import { loadPropsAPI } from '../../../redux/properties/index.api';
/* -----------Selectors------------ */
import { referentielSelector } from '../../../redux/referentiel/index.selector';
import { filterFormSearchListSelector, PropListSelector, PropsAreaSelector, PropsCategSelector } from '../../../redux/properties/index.selector';
/* -----------Style Sheet------------ */
import "./index.css";


const SearchGrid = () => {
    /* -----------Selector------------ */
    const propsList = useSelector(PropListSelector);
    const propsAreaList = useSelector(PropsAreaSelector);
    const propsCategList = useSelector(PropsCategSelector)
    const appVersion = useSelector(referentielSelector);
    const filterForm = useSelector(filterFormSearchListSelector);
    /* -----------Vars------------ */
    let publicUrl = process.env.PUBLIC_URL
    let imagealt = 'image'
    const dispatch = useDispatch();
    /* -----------Functions------------ */
    const onFiltersChange = (name, val) => {
        if (["min", "max"].includes(name)) {
            dispatch(updateFilter({ [name]: val }))
        } else {
            let { target: { value } } = val;
            dispatch(updateFilter({ [name]: value }))
        }
    }
    const search_handler = (e) => {
        e.preventDefault()
        let payload = { ...filterForm, section: 'search' }
        dispatch(loadPropsAPI(payload));
    }
    const toDetails_handler = (id) => {
        document.location.href = '/detail-de-l-offre/' + id
    }
    const buttonToLink = {
        border: 'none',
        cursor: 'pointer'
    }

    return (
        <div className="search-page-wrap pd-top-100 pd-bottom-70">
            <div className="search-container">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 sitebar">
                            <h6 className="filter-title mb-4"><img className="mr-3" src={publicUrl + "assets/img/icons/18.png"} alt={imagealt} />Filtres</h6>
                            <form className="widget widget-sidebar-search-wrap">
                                <div className="widget-sidebar-search">
                                    <div className="widget-sidebar-item-wrap rld-single-select">
                                        <select className="select single-select" onChange={(e) => onFiltersChange("area", e)} value={filterForm?.area ? filterForm?.area : 0}>
                                            <option value={0}>Secteur Géographique</option>
                                            {propsAreaList.map((item, i) => <option key={"SG" + i} value={item?.IDArea}>{item?.Libelle}</option>)}
                                        </select>
                                    </div>
                                    <div className="widget-sidebar-item-wrap rld-single-select">
                                        <select className="select single-select" onChange={(e) => onFiltersChange("category", e)} value={filterForm?.category ? filterForm?.category : 0}>
                                            <option value={0}>Activité</option>
                                            {propsCategList.map((item, i) => <option key={"AC" + i} value={item?.IDCategory}>{item?.Libelle}</option>)}
                                        </select>
                                    </div>
                                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                                        <div className="title">Prix minimum</div>
                                        <div className="price">
                                              <span>
                                                {parseInt("0").toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                                              </span>
                                              <span className="float-right">
                                                {parseInt("200000").toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                                              </span>
                                        </div>
                                        <Slider value={filterForm?.min} min={0} max={200000} onChange={(e) => onFiltersChange("min", e)} />
                                    </div>
                                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                                        <div className="title">Prix maximum</div>
                                        <div className="price">
                                          <span>
                                            {parseInt("100000").toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                                          </span>
                                                                <span className="float-right">
                                            {parseInt("2000000").toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                                          </span>
                                        </div>
                                        <Slider value={filterForm?.max} min={100000} max={2000000} onChange={(e) => onFiltersChange("max", e)} />
                                    </div>
                                </div>
                                <div className="btn-wrap text-center">
                                    <button className="btn btn-yellow" onClick={search_handler}><span className="left"><i className="fa fa-search" /></span>Rechercher</button>
                                </div>
                            </form>
                        </div>

                        <div className="col-xl-9 col-lg-8">

                            <div className="row mb-3">
                                <div className="col-md-9 col-sm-8">
                                    <h6 className="filter-title mt-3 mb-lg-0">{propsList.length} offre{propsList.length > 1 ? "s" : ""}</h6>
                                </div>
                                <div className="col-md-3 col-sm-4"></div>
                            </div>

                            <div className="row justify-content-center">

                                {propsList.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty" />
                                    :
                                    <>
                                        {propsList.map((item, i) => {
                                            let {
                                                Slug,
                                                areaName,
                                                imgSrc,
                                                imgAlt,
                                                Denomination,
                                                PrixEuros,
                                                nbBedroom,
                                                nbBathroom,
                                                surfaceBien,
                                                EBE,
                                                CAHT,
                                                tagBgColor,
                                                tagName,
                                                tagTagSide,
                                                tagTextColor,
                                                effectifs
                                            } = item;
                                            let formatedCAHT  = parseFloat(CAHT).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €';
                                            let formatedEBE   = parseFloat(EBE).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €';
                                            let tagSide = tagTagSide ? tagTagSide : '';
                                                return (
                                                <div key={"PR" + i} className="col-xl-4 col-sm-6">
                                                    <div className="single-feature">
                                                        <div className="thumb">
                                                            <img src={imgSrc ? imgSrc : placeholder_prop} alt={imgAlt} onClick={() => { toDetails_handler(Slug) }} style={buttonToLink} />
                                                        </div>
                                                        <div className="details">
                                                            <p className="author"><i className="fa fa-map-marker" /> {areaName}</p>
                                                            <h6 className="title readeal-top" title={Denomination}><a href={`/detail-de-l-offre/${Slug}`}>{Denomination}</a></h6>
                                                            <h6 className="price">
                                                                {PrixEuros ? PrixEuros.toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €' : ""}
                                                            </h6>
                                                            <ul className="info-list">
                                                                {appVersion.version === "cfc" ?
                                                                    <>
                                                                        {CAHT ? <li key={"CAHT" + i} ><span className={" text-yellow"}>CA:</span><br />{formatedCAHT}</li> : ""}
                                                                        {effectifs ? <li key={"EFF" + i} ><span className={" text-yellow"}>Effectifs:</span><br />{effectifs}</li> : ""}
                                                                        {EBE ? <li key={"EBE" + i} ><span className={" text-yellow"}>EBE:</span><br />{formatedEBE}</li> : ""}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <li key={"IL-BE" + i} ><i className="fa fa-bed" /> {nbBedroom ? nbBedroom : "--"} Bed</li>
                                                                        <li key={"IL-BA" + i} ><i className="fa fa-bath" /> {nbBathroom ? nbBathroom : "--"}  Bath</li>
                                                                        <li key={"sq" + i}>
                                                                            <img src={publicUrl + "/assets/img/icons/7.png"} alt="img" />
                                                                            {surfaceBien ? surfaceBien : "--"} sq
                                                                        </li>
                                                                    </>
                                                                }
                                                            </ul>
                                                        </div>
                                                        {tagSide &&
                                                            <div className={tagSide + " ribbon"}><span style={{background: tagBgColor, color: tagTextColor}}>{tagName}</span></div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </>
                                }

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
};

export default SearchGrid;