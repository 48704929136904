import Modal from 'antd/lib/modal';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { CloseCircleOutlined } from '@ant-design/icons';
import Typography from 'antd/lib/typography';
import "./styles.css";


const { Paragraph } = Typography;
const NotificationComponent = (isWrap, type, title, cdt1, cdt2, cdt3, cdt4) => {
    Modal[type]({
        title: title,
        wrapClassName: isWrap ? "wrapperNotif" : null,
        icon: <ExclamationCircleFilled />,
        content:
            isWrap ?
                <Paragraph>
                    {!cdt1 ?
                        (<div className='rowAlert'><CloseCircleOutlined className="site-result-demo-error-icon" /> Nom obligatoire</div>)
                        : null}
                    {!cdt2 ?
                        (<div className='rowAlert'><CloseCircleOutlined className="site-result-demo-error-icon" /> Email obligatoire</div>)
                        : null}
                    {!cdt3 ?
                        (<div className='rowAlert'><CloseCircleOutlined className="site-result-demo-error-icon" /> Email invalide</div>)
                        : null}
                    {!cdt4 ?
                        (<div className='rowAlert'><CloseCircleOutlined className="site-result-demo-error-icon" /> Captcha non vérifié</div>)
                        : null}
                </Paragraph>
                :
                null
        ,
        okText: 'OK',
        okType: 'success',
    });
};

export default NotificationComponent;