import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';

import HonorairesSection from "./section-components/honoraires";
import ContactSection from "./section-components/contact-section";

import Footer from './global-components/footer';

const Honoraires = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Honoraires" bgimage="assets/images/bandeaux/agence-valucian-cession-commerces-01.jpg" bgalign="center top" />
        <HonorairesSection />
        <ContactSection />
        <Footer />
    </div>
}

export default Honoraires