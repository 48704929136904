import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Mission from './section-components/notre-agence-missions';
import AboutUs from './section-components/notre-agence-about-us';
import DreamSection from "./section-components/notre-agence-dream";

import BuyOrSell from './section-components/buy-or-sell';

import Footer from './global-components/footer';

const NotreAgence = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Notre agence" bgimage="assets/images/bandeaux/agence-valucian-cession-commerces-01.jpg" bgalign="center top" />
        <Mission />
        <AboutUs />
        <DreamSection />
        <BuyOrSell />
        <Footer />
    </div>
}

export default NotreAgence