import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import footerdata from '../../data/footerdata.json';
import {env} from "../../environnement";

class ValFooter extends Component {

    componentDidMount() {
        let publicUrl = process.env.PUBLIC_URL
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);
    }

    render() {

        let publicUrl = env.publicURL
        const inlineStyle = {
            backgroundImage: 'url('+publicUrl+'/'+footerdata.footerbg+')'
        }

        return (
            <footer className="footer-area">
                <div className="container">

                    <div className="footer-top">
                        <div className="row">
                            <div className="col-sm-4">
                                <a className="footer-logo" href="#"><img src={ publicUrl+footerdata.footerlogo } alt={ footerdata.footerlogoalt } /></a>
                            </div>
                            <div className="col-sm-8">
                                <div className="footer-social text-sm-right">
                                    <span>Suivez-nous</span>
                                    <ul className="social-icon">
                                        { footerdata.socialicon.map( ( item, i )=>
                                            <li key={i}>
                                                <a href={ item.url } target="_blank"><i className={ item.icon } /></a>
                                            </li>
                                        ) }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <div className="widget widget_nav_menu">
                                    <h4 className="widget-title">{ footerdata.adresses.title }</h4>
                                    <ul>
                                        { footerdata.adresses.lignes.map( ( item, i )=>
                                            <li className="readeal-top adresses" key={ i }>{ item.title } </li>
                                        )
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 offset-lg-1">
                                <div className="widget widget_nav_menu">
                                    <h4 className="widget-title">{ footerdata.quicklink_1.title }</h4>
                                    <ul>
                                        { footerdata.quicklink_1.links.map( ( item, i )=>
                                            <li className="readeal-top" key={ i }><Link to={ item.url } >{ item.title }</Link></li>
                                        ) }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 offset-lg-1">
                                <div className="widget widget_nav_menu">
                                    <h4 className="widget-title">{ footerdata.quicklink_3.title } &nbsp;</h4>
                                    <ul>
                                        { footerdata.quicklink_3.links.map( ( item, i )=>
                                            <li className="readeal-top" key={ i }><Link to={ item.url } >{ item.title }</Link></li>
                                        ) }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right text-center" dangerouslySetInnerHTML={{__html: footerdata.copyrighttext}}></div>
                </div>
            </footer>

        )
    }
}


export default ValFooter