import { ObjetToArr } from "../../assets/functions";



export const updateFilterUtil = (fiche, editedData) => {

    for (const [key, value] of Object.entries(editedData)) {
        if (!value || (["area", "category"].includes(key) && value === 0)) {
            delete fiche[key]
        } else {
            fiche[key] = value;
        }
    }

    return fiche
}

export const loadPropsDetailsUtil = (data) => {
    let categories = "";
    ObjetToArr(data?.propertyCategories).map(item => categories = categories + ", " + item.categoryName)
    data.categories = categories.slice(1);
    return data
}