import React, { useEffect, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import API_ENDPOINT from "../../../api/api_endpoint";
import { getApi } from "../../../api/api_methods";
import partnersText from "../../../data/partners.json";
import NotificationComponent from "../../alert-component/component";
import "./partner.css";

const OurPartner = () => {
	const [partners, setPartners] = useState();

	const data = partnersText;
	const carouselOptions = {
		items: 4,
		rewind: true,
		autoplay: true,
		dots: false,
		margin: 10,
		loop: true,
	};

	console.log("partners:", partners);

	useEffect(() => {
		getApi(API_ENDPOINT.LOAD_PARTNERS)
			.then((res) => {
				const response = res?.data?.length ? res.data : [];
				setPartners([...response]);
			})
			.catch((err) => {
				NotificationComponent(
					false,
					"error",
					"Erreur de chargement des partenaires"
				);
			});
	}, []);

	const partnerList = () => {
		return partners?.map((partner, i) => (
			<a
				key={i}
				href={
					partner.partnerWebsite?.includes("http")
						? partner.partnerWebsite
						: `https://${partner.partnerWebsite}`
				}
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					width={"350px"}
					height={"94px"}
					src={partner.mediaSrc}
					alt={partner.mediaAlt}
					title={partner.partnerName}
				/>
			</a>
		));
	};



	return partners?.length ? (
		<div className="our-partner client-area pd-top-92 pd-bottom-100">
			<div className="container">
				<div className="section-title text-center">
					<h2 className="title">{data.title}</h2>
				</div>
				<ReactOwlCarousel className="owl-theme" {...carouselOptions}>
					{partnerList()}
				</ReactOwlCarousel>
			</div>
		</div>
	) : null;
};

export default OurPartner;
