import React, { Component } from 'react';
import hdata from '../../data/honoraires.json';

class HonorairesSection extends Component {

    render() {

        let data = hdata.honoraires

        return <div className="honoraires-area pd-top-100 pd-bottom-120">
            <div className="container">
                <div className="section-title">
                    <h4 className="text-center">NOS HONORAIRES - TARIFS MAXIMUM APPLIQUÉS</h4>
                </div>
                <div className="section-table">
                    <p className="text-center title-honoraires">{ data.title1 }</p>
                    <table className="table  table-honoraires">
                        <tr>
                            <th>Prix de vente</th>
                            <th>Tarif appliqué</th>
                        </tr>
                        <tbody>
                        <tr>
                            <td>Jusqu’à 60 000€</td>
                            <td>forfait de 6000€ TTC</td>
                        </tr>
                        <tr>
                            <td>De 60 001€ à 100 000€</td>
                            <td>10% TTC du prix de vente</td>
                        </tr>
                        <tr>
                            <td>De 100 001€ à 250 000€</td>
                            <td>9% TTC du prix de vente</td>
                        </tr>
                        <tr>
                            <td>De 250 001€ à 450 000€</td>
                            <td>7,5% TTC du prix de vente</td>
                        </tr>
                        <tr>
                            <td>Au-delà de 450 000€</td>
                            <td>6,5% TTC du prix de vente</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>

                    <p className="mg-top-50 text-center title-honoraires">{ data.title2 }</p>
                    <table className="table table-honoraires">
                        <tr>
                            <th>Prestations proposées</th>
                            <th>Tarif appliqué</th>
                        </tr>
                        <tbody>
                        <tr>
                            <td>Négociation</td>
                            <td>1 mois de loyer HT + TVA 20%</td>
                        </tr>
                        <tr>
                            <td>Négociation + rédaction bail commercial</td>
                            <td>1 mois de loyer HT + TVA 20%<br />Minimum forfaitaire de 1000€ HT</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    }



}

export default HonorairesSection