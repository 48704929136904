export const generateShareLink = (url, message) => {
	return {
		Facebook: generateFacebookShareLink(url, message),
		Twitter: generateTwitterShareLink(url, message),
		LinkedIn: generateLinkedinShareLink(url, message),
		WhatsApp: generateWhatsappShareLink(url, message),
	};
};

export const generateFacebookShareLink = (url, message) => {
	const params = {
		u: url,
	};
	return (
		"https://www.facebook.com/sharer/sharer.php?" +
		new URLSearchParams(params)
	);
};

export const generateTwitterShareLink = (url, message) => {
	const paras = {
		text: message,
		url: url,
	};
	return "https://twitter.com/intent/tweet?" + new URLSearchParams(paras);
};

export const generateLinkedinShareLink = (url, message = "TITLE") => {
	const params = {
		text: message + " " + url,
	};
	return (
		"https://www.linkedin.com/feed/?shareActive=true&" + new URLSearchParams(params)
	);
};

export const generateWhatsappShareLink = (url, message) => {
	const params = {
		text: message,
		url: url,
	};
	return "https://api.whatsapp.com/send?" + new URLSearchParams(params);
};
