import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import { Helmet, HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("neelaps"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <title>Valucian</title>
          <meta
            name='description'
            content='Valucian - Spécialiste de la cession de fonds de commerces'
          />
          <meta
            property='author'
            content='Valucian - Cession de fonds de commerce'
          />
        </Helmet>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </Provider>
);
