import api from "./api";
import qs from "qs";

export const getApi = (url, payload) => {
    return api().get(url, {
        params: {
            ...payload,
        },
    });
};

export const postApi = (url, payload) => {
    const data = qs.stringify(payload, { indices: false });
    return api().post(url, data);
};
