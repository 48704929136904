import API_ENDPOINT from "../../api/api_endpoint";
import { postApi } from "../../api/api_methods";
import NotificationComponent from "../../components/alert-component/component";
import { initContactForm } from "./index.slice";


export function sendMailContactAPI(payload, callback) {
    return async (dispatch) => {
        postApi(API_ENDPOINT.SEND_MAIL + '?option=contactForm', payload)
            .then((res) => {
                dispatch(initContactForm())
                NotificationComponent(false,
                    "success",
                    "Votre message a bien été envoyé.\r\n\r\nUn de nos conseillers reviendra vers vous très rapidement.",
                )
            })
            .catch((err) => {
                NotificationComponent(false,
                    "error",
                    "Erreur lors de l'envoi du message",
                )
            });
    };
}

export function sendMailDmdeInfoAPI(payload, callback) {
    return async (dispatch) => {
        postApi(API_ENDPOINT.SEND_MAIL + '?option=dmdeInfoForm', payload)
            .then((res) => {
                dispatch(initContactForm())
                NotificationComponent(false,
                    "success",
                    "Votre message a bien été envoyé.\r\n\r\nUn de nos conseillers reviendra vers vous très rapidement.",
                )
            })
            .catch((err) => {
                NotificationComponent(false,
                    "error",
                    "Erreur lors de l'envoi du message",
                )
            });
    };
}