import React from "react";
import newsData from "../data/news.json";
import Footer from "./global-components/footer";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import NewsList from "./news-components/news-list";

const News = () => {
	let data = newsData.navHeader;
	return (
		<div>
			<Navbar />
			<PageHeader
				headertitle={data.title}
				subheader={data.subTitle}
			/>
			<NewsList />
			<Footer />
		</div>
	);
};

export default News;
