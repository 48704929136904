import { combineReducers } from "redux";
/* -----------Reducers------------ */
import contactSlice from "./contact/index.slice";
import propertieSlice from "./properties/index.slice";
import referentielSlice from "./referentiel/index.slice";
import newsSlice from "./news/news.slice";

/* -----------COMBINE------------ */
const rootReducer = combineReducers({
  contact: contactSlice,
  properties: propertieSlice,
  referentiel: referentielSlice,
  news: newsSlice
});

export default rootReducer;