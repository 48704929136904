import React, { Component } from "react";
import { Link } from "react-router-dom";

class Page_header extends Component {
	render() {
		let HeaderTitle = this.props.headertitle;
		let BgImage = this.props.bgimage;
		let BgAlign = this.props.bgalign;
		let publicUrl = process.env.PUBLIC_URL;
		let Subheader = this.props.subheader
			? this.props.subheader
			: HeaderTitle;
		let subLink = this.props.subLink;
		let subLinkText = this.props.subLinkText;
		let defaultBgImage = this.props.defaultBgImage ? this.props.defaultBgImage : publicUrl + "assets/img/page-bg/1.jpg";
		const inlineStyle = {
			backgroundImage: ["url(" + BgImage + ")", "url(" + defaultBgImage + ")"],
			backgroundPosition: " " + BgAlign + " ",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
		};
		return (
			<div className="breadcrumb-area jarallax" style={inlineStyle}>
				<div className="header-overlay"></div>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcrumb-inner">
								<h1 className="page-title">{HeaderTitle}</h1>
								<ul className="page-list">
									<li>
										<Link to="/">Accueil&nbsp;</Link>
									</li>
									{subLink ? (
										<li>
											<Link to={"/" + subLink}>
												{subLinkText}&nbsp;
											</Link>
										</li>
									) : (
										""
									)}
									<li>&nbsp;{Subheader}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Page_header;
