/* -----------Assets------------ */
import React, { Component } from 'react';
import Empty from 'antd/lib/empty';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import placeholder_prop from "../../../assets/images/placeholder_prop.jpeg";
import { useDispatch } from "react-redux";
import { updateFilter } from '../../../redux/properties/index.slice';
import Slider from "antd/lib/slider"
import { loadPropsAPI } from '../../../redux/properties/index.api';
/* -----------Selectors------------ */
import { referentielSelector } from '../../../redux/referentiel/index.selector';
import { filterFormSearchListSelector, PropListSelector, PropsAreaSelector, PropsCategSelector } from '../../../redux/properties/index.selector';
/* -----------Style Sheet------------ */
import "./index.css";






const SearchList = () => {
  /* -----------Selector------------ */
  const propsList = useSelector(PropListSelector);
  const propsAreaList = useSelector(PropsAreaSelector);
  const propsCategList = useSelector(PropsCategSelector)
  const appVersion = useSelector(referentielSelector);
  const filterForm = useSelector(filterFormSearchListSelector);
  /* -----------Vars------------ */
  let publicUrl = process.env.PUBLIC_URL
  let imagealt = 'image'
  const dispatch = useDispatch();
  /* -----------Functions------------ */
  const onFiltersChange = (name, val) => {
    if (["min", "max"].includes(name)) {
      dispatch(updateFilter({ [name]: val }))
    } else {
      let { target: { value } } = val;
      dispatch(updateFilter({ [name]: value }))
    }
  }
  const search_handler = (e) => {
    e.preventDefault()
    let payload = { ...filterForm, section: 'search' }
    dispatch(loadPropsAPI(payload));
  }
  return (
      <div className="search_list search-page-wrap pd-top-100 pd-bottom-70">
        <div className="search-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 sitebar">
                <h6 className="filter-title mb-4"><img className="mr-3" src={publicUrl + "assets/img/icons/18.png"} alt={imagealt} />Filter</h6>
                <form className="widget widget-sidebar-search-wrap">
                  <div className="widget-sidebar-search">
                    <div className="widget-sidebar-item-wrap rld-single-select">
                      <select className="select single-select" onChange={(e) => onFiltersChange("area", e)} value={filterForm?.area ? filterForm?.area : 0}>
                        <option value={0}>Secteur Géographique</option>
                        {propsAreaList.map((item, i) => <option key={"SG" + i} value={item?.IDPropertyArea}>{item?.Libelle_fr}</option>)}
                      </select>
                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-select">
                      <select className="select single-select" onChange={(e) => onFiltersChange("category", e)} value={filterForm?.category ? filterForm?.category : 0}>
                        <option value={0}>Activité</option>
                        {propsCategList.map((item, i) => <option key={"AC" + i} value={item?.IDPropertyCategory}>{item?.Libelle_fr}</option>)}
                      </select>
                    </div>
                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                      <div className="title">Prix minimum</div>
                      <div className="price">
                      <span>
                        {parseInt("0").toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                      </span>
                        <span className="float-right">
                        {parseInt("200000").toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                      </span>
                      </div>
                      <Slider value={filterForm?.min} min={0} max={200000} onChange={(e) => onFiltersChange("min", e)} />
                    </div>
                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                      <div className="title">Prix maximum</div>
                      <div className="price">
                      <span>
                        {parseInt("100000").toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                      </span>
                        <span className="float-right">
                        {parseInt("2000000").toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                      </span>
                      </div>
                      <Slider value={filterForm?.max} min={100000} max={2000000} onChange={(e) => onFiltersChange("max", e)} />
                    </div>
                  </div>
                  <div className="btn-wrap text-center">
                    <button className="btn btn-yellow" onClick={search_handler}><span className="left"><i className="fa fa-search" /></span>Find Property</button>
                  </div>
                </form>
              </div>
              <div className="col-xl-8 col-lg-8">
                <div className="row mb-3">
                  <div className="col-md-9 col-sm-8">
                    <h6 className="filter-title mt-3 mb-lg-0">{propsList.length} Properties</h6>
                  </div>
                </div>
                {propsList.length === 0 ?
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty" />
                    : <>
                      {propsList.map((item, i) => {
                        let { Slug, areaName, imgSrc, imgAlt, Denomination, PrixEuros, nbBedroom, nbBathroom, surfaceBien, EBE, CAHT, effectifs } = item;
                        return (
                            <div key={"PR" + i} className="single-feature style-two">
                              <div className="thumb">
                                <img src={imgSrc ? imgSrc : placeholder_prop} alt={imgAlt} />
                              </div>
                              <div className="details">
                                <div className="details-wrap">
                                  <p className="author"><i className="fa fa-map-marker" /> {areaName}</p>
                                  <h6 className="title readeal-top" title={Denomination}><a>{Denomination}</a></h6>
                                  <h6 className="price">
                                    {PrixEuros ? PrixEuros.toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €' : ""}
                                  </h6>
                                  <ul className="info-list">
                                    {appVersion.version === "cfc" ?
                                        <>
                                          {CAHT ? <li key={"CAHT" + i} ><span>CA:</span>{CAHT.toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ')} €</li> : ""}
                                          {effectifs ? <li key={"EFF" + i} ><span>Effectifs:</span> {effectifs}</li> : ""}
                                          {EBE ? <li key={"EBE" + i} ><span>EBE:</span> {EBE} €</li> : ""}
                                        </>
                                        :
                                        <>
                                          <li key={"IL-BE" + i} ><i className="fa fa-bed" /> {nbBedroom ? nbBedroom : "--"} Bed</li>
                                          <li key={"IL-BA" + i} ><i className="fa fa-bath" /> {nbBathroom ? nbBathroom : "--"}  Bath</li>
                                          <li key={"sq" + i}>
                                            <img src={publicUrl + "/assets/img/icons/7.png"} alt="img" />
                                            {surfaceBien ? surfaceBien : "--"} sq
                                          </li>
                                        </>
                                    }
                                  </ul>
                                  <ul className="contact-list">
                                    <li><a className="phone" href="#"><i className="fa fa-phone" /></a></li>
                                    <li className="readeal-top" ><Link className="btn btn-yellow" to={`/property-details/${Slug}`} >View Details</Link></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                        )
                      })
                      }
                    </>}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default SearchList;