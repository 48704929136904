import React, { Component } from 'react';

class Legals extends Component {

    render() {

    return <div className="buy-sell-area pd-top-100">
        <div className="container">
            <div className="property-news-single-card style-two pb-0 mb-0">
                <div className="mb-5">
                    <h4>PROPRIÉTAIRE</h4>
                    <p><strong>SARL VALUCIAN</strong><br />Siret : 910 581 768 00025.<br />Siège social : 12 Rue du Duc de Guise – 62100 CALAIS</p>
                </div>
                <div className="mb-5">
                    <h4>NOTRE MÉDIATEUR :</h4>
                    <p><a href="https://www.mediation-vivons-mieux-ensemble.fr/contact/info/obligations-immo/" target="_blank">Médiation - vivons mieux ensemble</a></p>
                </div>
                <div className="mb-5">
                    <h4>RESPONSABLE DE PUBLICATIONS</h4>
                    <p><strong>Monsieur XAVIER DEMOURY</strong><br />Email : <a href="mailto:contact@valucian.fr">contact@valucian.fr</a></p>
                </div>
                <div className="mb-5">
                    <h4>LE SITE INTERNET</h4>
                    <p>
                        VALUCIAN.FR a été développé par la société :
                        <br />SASU ELAPIDA
                        <br />Co SAE BP 1347 Public -97133 Saint-Barthélemy - Antilles françaises
                        <br />Web : <a href="https://www.elapida.fr" target="_blank">www.elapida.fr</a>
                        <br />Email : <a href="contact@elapida.fr">contact@elapida.fr</a>
                        <br />RCS Basse-Terre 807 913 231 00013
                        <br /><br />VALUCIAN.FR est hébergé par la SASU ELAPIDA en partenariat avec la société OVH
                    </p>
                </div>
                <div className="mb-5">
                    <h4>INFORMATIQUE ET LIBERTÉS, RÈGLEMENTATION GÉNÉRALE SUR LA PROTECTION DES DONNÉES</h4>
                    <p>VALUCIAN.FR recueille des informations (données nominatives) vous concernant lors de la création de votre Compte Client : nom, prénom, adresse électronique. Les informations accompagnées d'un astérisque sont obligatoires. Ces informations nous permettent de vous identifier, assurer le suivi de vos achats. Ces données ne sont pas transmises à des tiers. Seule notre société est destinataire des informations que vous nous communiquez, sauf autorisation de votre part. VALUCIAN.FR a pour politique de ne pas céder les données concernant ses clients. VALUCIAN.FR est donc seul destinataire des données que vous nous transmettez, sauf demande officielle de la part des autorités et/ou autorisation de votre part.
                        <br /><br />
                        Nous nous sommes engagés à assurer la sécurité des données à caractère personnel via des procédures strictes au sein de notre entreprise. VALUCIAN.FR s'oblige à mettre tous ses efforts en œuvre afin de protéger vos données nominatives. Conformément aux dispositions de la Loi Informatique et Libertés du 6 janvier 1978 (modifiée par la loi du 6 août 2004 relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel), et en conformité avec le Règlement Général sur la Protection des Données (RGPD) du 28 mai 2018, vous bénéficiez d'un droit d'accès, de rectification, d'opposition sur vos données nominatives collectées.
                        <br /><br />
                        Vous pouvez vous opposer au traitement et à la conservation des données vous concernant et disposez du droit de retirer votre consentement à tout moment en nous adressant un simple mail contact@VALUCIAN.FR ou via notre formulaire de contact.
                        <br /><br />
                        En conformité avec le Règlement Général sur la Protection des Données (RGPD) du 28 mai 2018, vous pouvez nous contacter directement par mail afin de supprimer vos données personnelles : contact@ VALUCIAN.FR ou via notre formulaire de contact.
                        <br /><br />
                        <ul>
                            <li>Notre Responsable de Traitement : Monsieur Xavier DEMOURY</li>
                            <li>Les coordonnées du Délégué à la Protection des données : Monsieur Xavier DEMOURY - contact@valucian.fr</li>
                            <li>La finalité des traitements : relation de communication d'échanges de mails, relation commerciale suite à un achat en ligne, établissement facturation, accusé réception pour le client de la prise en compte de son achat.</li>
                            <li>Les personnes pouvant y accéder : Seules les personnes en interne de la société peuvent accéder aux données à caractère personnel</li>
                            <li>Le type de données collectées : Nom, prénom, adresse mail, numéro de téléphone. L’adresse postale est demandée lors de l'établissement de facture liée à l’achat en ligne</li>
                            <li>Leur durée de conservation : Maximum 5 années à compter du dernier contact, et de 10 ans Maximum à compter de l'établissement de la dernière facture.</li>
                        </ul>
                    </p>
                </div>
                <div className="mb-5">
                    <h4>DROIT DE PROPRIÉTÉ INTELLECTUELLE</h4>
                    <p>
                        Tous les éléments présents sur le site et le site en lui-même sont protégés par le droit d’auteur, et/ou tout autre droit de la propriété intellectuelle. Par éléments, nous entendons de façon non exhaustive : les photographies, images, dessins, illustrations, textes, vidéos, logos, marques, modèles, logiciels… Ces éléments appartiennent à la société valucian.fr ou bien sont utilisés avec l’accord de leurs propriétaires. A ce titre, toute reproduction, représentation, utilisation, adaptation, modification, incorporation, traduction, commercialisation, partielles ou intégrales par quelque procédé et sur quelque support que ce soit (papier, numérique…) sont interdites, sans l’autorisation écrite préalable, hormis les exceptions visées à l’article L 122.5 du Code de Propriété Intellectuelle, sous peine de constituer un délit de contrefaçon de droit d’auteur et/ou de dessins et modèles et/ou de marque.
                    </p>
                </div>
            </div>
        </div>
      </div>
        }
    }

export default Legals