import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	newsList: [],
	newsDetails: {},
};
const news = createSlice({
	name: "news",
	initialState,
	reducers: {
		loadNewsList: (state, { payload }) => {
			state.newsList = payload;
		},
		loadNewsDetails: (state, { payload }) => {
			state.newsDetails = payload;
		},
	},
});

export const {
	loadNewsList,
	loadNewsDetails,
} = news.actions;

export default news.reducer;
