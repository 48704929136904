/* -----------Assets------------ */
import React from 'react';
import Empty from 'antd/lib/empty';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import placeholder_prop from "../../../assets/images/placeholder_prop.jpeg";
/* -----------Selectors------------ */
import {PropListSelector} from "../../../redux/properties/index.selector";
import {referentielSelector} from "../../../redux/referentiel/index.selector";
/* -----------Style Sheet------------ */
import "./index.css";

const Featured = (props) => {
  /* -----------Selector------------ */
  const propsList   = useSelector(PropListSelector);
  const appVersion  = useSelector(referentielSelector);
  /* -----------Vars------------ */
  let publicUrl = process.env.REACT_APP_PUBLIC_URL
  let noData = propsList.length < 0;
  let Customclass = props.Customclass ? props.Customclass : 'pd-top-60'

  /* -----------FUNCTIONS------------ */
  const toDetails_handler = (slug) => {
    document.location.href = '/detail-de-l-offre/' + slug
  }
  const buttonToLink = {
    border: 'none',
    cursor: 'pointer'
  }

  return (
    <div className={"featured-area bg-gray  " + Customclass}>
      <div className="container">
        <div className="section-title text-center">
          <h2 className="title">Nos nouveautés</h2>
        </div>
        <div className="row justify-content-center"
          style={{ boxShadow: noData ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' : 'none' }}>
          {noData ?
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            :
            propsList.map((item, i) => {
              let { IDProperty, areaName, imgSrc, imgAlt, Denomination, PrixEuros, nbBedroom, nbBathroom, surfaceBien, EBE, CAHT, effectifs, tagBgColor, tagName, tagTagSide, tagTextColor, Slug } = item;
              let formatedCAHT  = parseFloat(CAHT).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €';
              let formatedEBE   = parseFloat(EBE).toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €';

              let tagSide = tagTagSide ? tagTagSide : '';
              return (
                <div key={i} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div style={{position:"relative"}}>
                  <div className={"single-feature"}>
                      <div className="thumb">
                          <img src={imgSrc ? imgSrc : placeholder_prop} alt={imgAlt} onClick={() => { toDetails_handler(Slug) }} style={buttonToLink} />
                      </div>
                    <div className="details">
                      <p className="author"><i className="fa fa-map-marker" /> {areaName}</p>
                      <h6 className="title readeal-top" title={Denomination}><a href={`/detail-de-l-offre/${Slug}`}>{Denomination}</a></h6>
                      <h6 className="price">
                        {PrixEuros.toLocaleString('en-US', { useGrouping: true }).replace(/,/g, ' ') + ' €'}
                      </h6>
                      <ul className="info-list">
                        {appVersion.version === "cfc" ?
                          <>
                            {CAHT ? <li key={i} ><span className={" text-yellow"}>CA:</span><br />{formatedCAHT}</li> : ""}
                            {EBE ? <li key={i} ><span className={" text-yellow"}>EBE:</span><br />{formatedEBE}</li> : ""}
                            {effectifs ? <li key={i} ><span className={" text-yellow"}>Effectif:</span><br />{effectifs}</li> : ""}
                          </>
                          :
                          <>
                            {/*}
                            <li key={i} ><i className="fa fa-bed text-yellow" /> {nbBedroom ? nbBedroom : "--"} Bed</li>
                            <li key={i} ><i className="fa fa-bath text-yellow" /> {nbBathroom ? nbBathroom : "--"} Bath</li>
                            <li>
                              <img src={publicUrl + "/assets/img/icons/7.png"} alt="img" />
                              {surfaceBien ? surfaceBien : "--"} sq
                            </li>
                            */}
                          </>
                        }
                      </ul>
                    </div>
                    {tagSide &&
                        <div className={tagSide + " ribbon"}><span style={{background: tagBgColor, color: tagTextColor}}>{tagName}</span></div>
                    }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div >
  );

}

export default Featured;