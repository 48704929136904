export const env = {
    NEELAPS_API_URL: "https://nre.neelaps.app/v1.0",
    publicURL: "https://www.valucian.fr/"
    /*
    NEELAPS_API_KEY: "ZWiYamGVZ2picZWdY20%3D.Ni04li2RShqt3ULIZgqQsHy6cwjMOxZTsPEqowkQCHuhrfP5IL.gKfUY1GSbVR%2BmtaFY2ZpZ1Nom2ppnXBlaVZilpWWYw%3D%3D.aLS0nJbOmKSkWbbKkqJXeaarxKSd4Z6npqaqoJOVocmYpMenpJCYpKFozdKYZZiqlKvEoquUmpiYl6zS2JSd1Jq0mQ%3D%3D",
    //NEELAPS_API_KEY: "ZWiYamGUaWlibpWaYWg%3D.cHRj4wxMaMfuKR1zNr3qBcPQPzEhac5KpaQoGR7HcY2Tw.hpnaY1GUbFR3nsaFY2ZpZ1NomGppmnBjZFZilpWWYw%3D%3D.aLSwppnQV3ignuDNpaqnp21mkp6dyqKUoqllx9TWYs2gn5WYp8OrlaOsk8mWnJipn6uRmqjMsmU%3D",
    RECAPTCHA_SITE_KEY: "6LctL_AkAAAAABu6oKvh5rgia8c2dVGUDXjjmw19",
    RECAPTCHA_SECRET_KEY: "6LctL_AkAAAAAOsCAVsyXmecsH07dlf3F2v0LXNG"
     */
};
