import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./root-reducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createBlacklistFilter } from 'redux-persist-transform-filter';


const BlacklistFilter = createBlacklistFilter('properties', ['filterForm_SearchList']);

const persistConfig = {
    key: 'root',
    storage: storage,
    transforms: [BlacklistFilter],
    blacklist: ['contact']
};

export default configureStore({
    // devTools: false,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ immutableCheck: false }),
    reducer: persistReducer(persistConfig, rootReducer),
});
