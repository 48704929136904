import React, { Component } from 'react';
import sectiondata from '../../data/repreneurs.json';
import parse from 'html-react-parser';
import {Link} from "react-router-dom";

class ServicesAcheteursPart2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL
        let imagealt = 'image'
        let data = sectiondata.repreneurs.item_2

        return <div className="services-repreneurs rld-1-3-bg pd-top-60 pd-bottom-40" style={{backgroundImage: 'url(assets/images/bg-grey.png)'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="shape-image-list-wrap">
                            <img className="shadow-img" src={ publicUrl+data.image } alt={ imagealt } />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="pd-left mb-0">
                            <div>
                                { data.items.map( ( item, i )=>
                                    <div className="mb-4">
                                        <h4 className="title inner-title"><i className={item.icon } />{ item.title }</h4>
                                        <p>{ item.content }</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ServicesAcheteursPart2