/* -----------Assets------------ */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import sectiondata from '../../data/banner.json';
import { loadPropsAreaAPI, loadPropsCategAPI, loadPropsAPI } from '../../redux/properties/index.api';
import { initFilter } from '../../redux/properties/index.slice';
import { updateFilter } from '../../redux/properties/index.slice';
/* -----------Selectors------------ */
import { filterFormSearchListSelector, PropsAreaSelector, PropsCategSelector } from '../../redux/properties/index.selector';
/* -----------Style Sheet------------ */


const Banner = () => {
    /* -----------Selectors------------ */
    const filterForm = useSelector(filterFormSearchListSelector);
    const propsAreaList = useSelector(PropsAreaSelector);
    const propsCategList = useSelector(PropsCategSelector)
    /* -----------State------------ */
    const [choosedTab, setChoosedTab] = useState("Acheter");
    /* -----------Effects------------ */
    useEffect(() => {
        dispatch(loadPropsAreaAPI({}));
        dispatch(loadPropsCategAPI({}))
        dispatch(initFilter())
    }, []);
    /* -----------Vars------------ */
    let data = sectiondata.banner
    const inlineStyle = {
        backgroundImage: 'url('+ data.image +')'
    }
    const dispatch = useDispatch();
    /* -----------FUNCTIONS------------ */
    const searchNow_handler = (e) => {
        //e.preventDefault()
        let payload = { ...filterForm, section: 'search' }
        dispatch(loadPropsAPI(payload));
    }
    const onChange_TAB = (e) => {

        if(e == "Acheter"){
            setChoosedTab(e)
        }else{
            document.location.href = '/vendeurs'
        }

    }
    const onFiltersChange = (name, val) => {
        dispatch(updateFilter({ [name]: val }))
    }

    return (
        <div className="banner-area jarallax" style={inlineStyle}>
            <div className="overlay"></div>
            <div className="container">
                <div className="banner-inner-wrap">
                    <div className="row">
                        <div className="col-lg-9 col-sm-12">
                            <div className="banner-inner">
                                <img src={data.logo} alt="logo" className="banner-logo" />
                                <h1 className="title">{ data.title1 }</h1>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="banner-search-wrap">
                                <ul className="nav nav-tabs rld-banner-tab">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#tabs_1" onClick={() => onChange_TAB("Acheter")}>Acheter</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabs_2" onClick={() => onChange_TAB("Vendre")}>Vendre</a>
                                    </li>
                                    {/*<li className="nav-item">
                                                        <a className="nav-link" data-toggle="tab" href="#tabs_2" onClick={()=>onChange_TAB("Louer")}>Louer</a>
                                      </li>*/}
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="tabs_1">
                                        <div className="rld-main-search">
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-6 col-md-6">
                                                    <div className="rld-single-select">
                                                        <select className="select single-select" onChange={(e) => onFiltersChange("area", e.target.value)} value={filterForm?.area ? filterForm?.area : 0}>
                                                            <option value={0}>Secteur Géographique</option>
                                                            {propsAreaList.map((item, i) => <option key={"SG" + i} value={item?.IDArea}>{item?.Libelle}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-6 col-md-6">
                                                    <div className="rld-single-select">
                                                        <select className="select single-select" onChange={(e) => onFiltersChange("category", e.target.value)} value={filterForm?.category ? filterForm?.category : 0}>
                                                            <option value={0}>Activité</option>
                                                            {propsCategList.map((item, i) => <option key={"AC" + i} value={item?.IDCategory}>{item?.Libelle}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-4 col-md-4">
                                                    <div className="rld-single-select">
                                                        <select className="select single-select" onChange={(e) => onFiltersChange("max", e.target.value)} value={filterForm?.max ? filterForm?.max : 0}>
                                                            <option value={0}>Budget maximum</option>
                                                            <option value={100000}>100 000€</option>
                                                            <option value={150000}>150 000€</option>
                                                            <option value={200000}>200 000€</option>
                                                            <option value={250000}>250 000€</option>
                                                            <option value={0}>Pas de budget max</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                                                    <Link className="btn btn-yellow" to={choosedTab === "Acheter" ? "/commerces" : "/vendeurs"} onClick={searchNow_handler}>RECHERCHER</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tabs_2">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner
/*
class Banner extends Component {

    componentDidMount() {

        const $ = window.$;

        if ($('.single-select').length){
            $('.single-select').niceSelect();
        }
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL
        let data = sectiondata.banner
        let bckimage = data.image

        const inlineStyle = {
            backgroundImage: 'url('+ bckimage +')'
        }

    return <div className="banner-area jarallax" style={inlineStyle}>
        <div className="overlay"></div>
          <div className="container">
            <div className="banner-inner-wrap">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <div className="banner-inner">
                    <img src={data.logo} alt="logo" className="banner-logo" />
                    <h1 className="title">{ data.title1 }</h1>
                  </div>
                </div>
                {/* /!\ NE PAS SUPPRIMER /!\
                <div className="col-12">
                  <div className="banner-search-wrap">
                    <ul className="nav nav-tabs rld-banner-tab">
                      <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#tabs_1">For Buy</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tabs_2">For Rent</a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="tabs_1">
                        <div className="rld-main-search">
                          <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6">
                              <div className="rld-single-input left-icon">
                                <input type="text" placeholder="Entry Landmark Location" />
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>All Commerces</option>
                                  <option value={2}>Commerces 1</option>
                                  <option value={3}>Commerces 2</option>
                                  <option value={3}>Commerces 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Room</option>
                                  <option value={2}>Room 1</option>
                                  <option value={3}>Room 2</option>
                                  <option value={3}>Room 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Any Price</option>
                                  <option value={2}>Price 1</option>
                                  <option value={3}>Price 2</option>
                                  <option value={3}>Price 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                              <Link className="btn btn-yellow" to="/search-list">SEARCH NOW</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tabs_2">
                        <div className="rld-main-search">
                          <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6">
                              <div className="rld-single-input left-icon">
                                <input type="text" placeholder="Entry Landmark Location" />
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>All Commerces</option>
                                  <option value={2}>Commerces 1</option>
                                  <option value={3}>Commerces 2</option>
                                  <option value={3}>Commerces 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Room</option>
                                  <option value={2}>Room 1</option>
                                  <option value={3}>Room 2</option>
                                  <option value={3}>Room 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Any Price</option>
                                  <option value={2}>Price 1</option>
                                  <option value={3}>Price 2</option>
                                  <option value={3}>Price 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                              <Link className="btn btn-yellow" to="/search-list">SEARCH NOW</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>

        </div>

        }
}
*/