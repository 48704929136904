import React, { Component } from 'react';
import sectiondata from '../../data/cedants.json';
import parse from 'html-react-parser';
import {Link} from "react-router-dom";

class ServicesAcheteursTitle extends Component {


    render() {

        let data = sectiondata.cedants

        return <div className="pd-top-100 services-repreneurs-title">
            <div className="container">
                <div className={"row"}>
                    <div className={"col-xl-9"}>
                        <div className="section-title">
                            <h5 className="sub-title">{ data.subtitle }</h5>
                            <h2 className="title">{ data.title }</h2>
                            <p>{ data.content }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ServicesAcheteursTitle