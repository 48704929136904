import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadNewsListAPI } from "../../redux/news/news.api";
import { NewsListSelector } from "../../redux/news/news.selector";
import "./news-list.css";
import { formatDate } from "../../utils/date.utils";

const NewsList = () => {
	const dispatch = useDispatch();

	const newsList = useSelector(NewsListSelector);

	useEffect(() => {
		dispatch(loadNewsListAPI());
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<div className="property-news-area pd-top-100 pd-bottom-70">
				<div className="container">
					<div className="row">
						{newsList.map((news, i) => (
							<div key={i} className="col-lg-6 mb-4">
								<div className="single-news">
									<div className="thumb">
										<img
											src={
												news.mediaSrc ??
												"assets/img/news/img-placeholder.jpg"
											}
											onError={(e) =>
												(e.target.src =
													"assets/img/news/img-placeholder.jpg")
											}
											alt={news.mediaAlt}
										/>
									</div>
									<div className="details">
										<h4>
											<Link to={news.Slug}>
												{news.Titre_fr}
											</Link>
										</h4>
										<p>{news.content}</p>
										<div className="author">
											<img
												width={33}
												height={33}
												src={
													news.authorProfilePicture ??
													"/assets/img/news/author-profile-picture-placeholder.jpg"
												}
												alt="Author profile picture"
											/>
											<span>{news.Author}</span>
											<span className="date"> - </span>
											<span className="date">
												{formatDate(news.DatePublication)}
											</span>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsList;
