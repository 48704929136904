/* -----------Components------------ */
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import PropertyDetailsSection from "./section-components/property_details/property-details";
import { useParams } from "react-router-dom";
import Footer from "./global-components/footer";
/* -----------Assets------------ */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
/* -----------Selectors------------ */
import { PropDetailSelector } from "../redux/properties/index.selector";
import { loadPropDetailsAPI } from "../redux/properties/index.api";
/* -----------Styles------------ */

const PropertyDetails = () => {
  /* -----------Selectors------------ */
  const propDetail = useSelector(PropDetailSelector);
  /*const { img } = propDetail;*/
  const { slug } = useParams();
  const dispatch = useDispatch();
  /* -----------Effect Hooks------------ */
  useEffect(() => {
    const rootElement = document.documentElement;
    rootElement.scrollTop = 0;
  }, []);
  useEffect(() => {
    let payload = {
      section: "detail",
      property: slug,
    };
    dispatch(loadPropDetailsAPI(payload));
  }, [slug]);

  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle={propDetail?.Denomination}
        subheader={propDetail?.Denomination}
        bgimage={propDetail?.imgSrc}
      />
      <PropertyDetailsSection />
      <Footer />
    </div>
  );
};

export default PropertyDetails;
