import { createSlice } from "@reduxjs/toolkit";
import { loadNavFAM_selectUtil } from "./index.util";


const initialState = {
    version: {},
    navbar_Famil: [],
    navbar_Famil_selected: null
};
const referentiel = createSlice({
    name: "referentiel",
    initialState,
    reducers: {
        loadVersion: (state, { payload }) => {
            state.version = payload;
        },
        loadNavFAM: (state, { payload }) => {
            state.navbar_Famil = payload;
        },
        loadNavFAM_select: (state, { payload }) => {
            state.navbar_Famil_selected = loadNavFAM_selectUtil(payload);
        },
    },
});

export const { loadVersion, loadNavFAM, loadNavFAM_select } = referentiel.actions;

export default referentiel.reducer;