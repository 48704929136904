import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';

import SectionMentions from "./section-components/mentions-legales";
import Footer from "./global-components/footer";
import ContactSection from "./section-components/contact-section";

const MentionsLegales = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Mentions légales" bgimage="assets/images/bandeaux/agence-valucian-cession-commerces-01.jpg" bgalign="center top" />
        <SectionMentions />
        <ContactSection />
        <Footer />
    </div>
}

export default MentionsLegales