/* -----------Components------------ */
import Acheteurs from "./components/acheteurs";
import SectionCommerces from "./components/commerces";
import Contact from "./components/contact";
import Home from "./components/homepage";
import Honoraires from "./components/honoraires";
import Legals from "./components/mentions-legales";
import NotreAgence from "./components/notre-agence";
import PropertyDetails from "./components/property-details";
import SearchGrid from "./components/search-grid";
import SearchListSection from "./components/search-list";
import Vendeurs from "./components/vendeurs";
/* -----------Assets------------ */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import News from "./components/news";
import NewsDetailsLayout from "./components/news-details-layout";
import {
	loadNavFamiliesAPI,
	loadVersionAPI,
} from "./redux/referentiel/index.api";

const App = () => {
	/* -----------Vars------------ */
	const dispatch = useDispatch();
	/* -----------Effect Hook------------ */
	useEffect(() => {
		dispatch(loadVersionAPI({ get: "neelaps-version" }));
		dispatch(loadNavFamiliesAPI({ get: "families" }));
	}, []);

	return (
		<Routes basename="/">
			<Route path="/" element={<Home />} />
			<Route
				path="/detail-de-l-offre/:slug"
				element={<PropertyDetails />}
			/>
			<Route path="/notre-agence" element={<NotreAgence />} />
			<Route path="/vendeurs" element={<Vendeurs />} />
			<Route path="/acheteurs" element={<Acheteurs />} />
			<Route path="/honoraires" element={<Honoraires />} />
			<Route path="/contact" element={<Contact />} />
			<Route path="/mentions-legales" element={<Legals />} />
			<Route path="/commerces" element={<SectionCommerces />} />
			<Route path="/search-grid" element={<SearchGrid />} />
			<Route path="/search-list" element={<SearchListSection />} />
			<Route path="/actualites" element={<News />} />
			<Route path="/actualites/:slug" element={<NewsDetailsLayout />} />
		</Routes>
	);
};

export default App;
