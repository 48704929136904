import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    formulaire: {}
};
const contact = createSlice({
    name: "contact",
    initialState,
    reducers: {
        setContactForm: (state, { payload }) => {
            state.formulaire = payload;
        },
        initContactForm: (state, { payload }) => {
            state.formulaire = initialState.formulaire;
        }
    },
});

export const {
    setContactForm,
    initContactForm
} = contact.actions;

export default contact.reducer;
