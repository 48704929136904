/* -----------Components------------ */
/* -----------Assets------------ */
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loadNavFAM_select } from '../../redux/referentiel/index.slice';
import { initFilter } from '../../redux/properties/index.slice';
/* -----------Selectors------------ */
import { navbarFamSelector } from '../../redux/referentiel/index.selector';
/* -----------Styles------------ */

const Navbar = () => {
    /* -----------Selectors------------ */
    const navFams = useSelector(navbarFamSelector);
    /* -----------Vars------------ */
    let publicUrl = process.env.PUBLIC_URL
    const dispatch = useDispatch();
    /* -----------Functions------------ */
    const to_searchList = (id) => {
        dispatch(initFilter())
        dispatch(loadNavFAM_select({ family: id }))
    }

    return (
        <div>
            <div className="navbar-area">
                <nav className="navbar navbar-area navbar-expand-lg">
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="icon-left" />
                                <span className="icon-right" />
                            </button>
                        </div>
                        <div className="logo readeal-top">
                            <Link to="/"><img src={publicUrl+"/assets/img/logo.svg"} alt="logo" className="top-logo" /></Link>
                        </div>

                        <div className="collapse navbar-collapse" id="realdeal_main_menu">
                            <ul className="navbar-nav menu-open readeal-top">
                                <li><Link to="/">Accueil</Link></li>
                                <li className="menu-item-has-children">
                                    <a href="#">Commerces</a>
                                    <ul className="sub-menu">
                                        <li><Link to="/commerces" onClick={() => { to_searchList(null) }}>Tous les Commerces</Link></li>
                                        {navFams.map(item => {
                                                let { IDPropertyFamille, Libelle_fr } = item;
                                                return (
                                                    <li key={IDPropertyFamille}><Link to="/commerces" onClick={() => { to_searchList(IDPropertyFamille) }}>{Libelle_fr}</Link></li>
                                                )
                                            }
                                        )}
                                    </ul>
                                </li>
                                {/*<li><Link to="/actualites">Actualités/Informations</Link></li>*/}
                                <li className="menu-item-has-children">
                                    <a href="#">Notre savoir faire</a>
                                    <ul className="sub-menu">
                                        <li><Link to="/notre-agence">Notre Agence</Link></li>
                                        <li><Link to="/vendeurs">Vendeurs</Link></li>
                                        <li><Link to="/acheteurs">Acheteurs</Link></li>
                                        <li><Link to="/honoraires">Honoraires</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/contact">Nous contacter</Link></li>
                            </ul>
                        </div>
                        <div className="nav-right-part nav-right-part-desktop readeal-top">
                            <a className="btn btn-yellow" href="tel:0982429420">09 82 42 94 20 <span className="right"><i className="la la-phone-square" /></span></a>
                        </div>
                    </div>
                </nav>
            </div >
        </div >
    )
}


export default Navbar