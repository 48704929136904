/* -----------Components------------ */
import NotificationComponent from "../../alert-component/component";
//import LoaderComponent from "../../loader-component";
/* -----------Assets------------ */
import Carousel from "antd/lib/carousel";
import Empty from "antd/lib/empty";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReCaptchaV2 from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton } from "react-share";
import { ObjetToArr } from "../../../assets/functions";
import footerdata from "../../../data/footerdata.json";
import { env } from "../../../environnement";
import {
  sendMailDmdeInfoAPI
} from "../../../redux/contact/index.api";
import { setContactForm } from "../../../redux/contact/index.slice";
/* -----------Selectors------------ */
import { contactFormSelector } from "../../../redux/contact/index.selector";
import { PropDetailSelector } from "../../../redux/properties/index.selector";
/* -----------Styles------------ */
import { Circle, MapContainer, Marker, TileLayer } from "react-leaflet";
import { referentielSelector } from "../../../redux/referentiel/index.selector";
import "./index.css";

const PropertyDetails = () => {
  /* -----------Selectors------------ */
  const propDetail = useSelector(PropDetailSelector);
  const contactForm = useSelector(contactFormSelector);
  /*const loader = useSelector(PropListLoaderSelector);*/
  const appVersion = useSelector(referentielSelector);
  const nl2br = require("react-nl2br");
  /* -----------State------------ */
  const [form, setForm] = useState({
    contactName: contactForm?.contactName,
    contactMail: contactForm?.contactMail,
    contactJour: contactForm?.contactJour,
    contactHoraire: contactForm?.contactHoraire,
    contactPhone: contactForm?.contactPhone,
    contactSubject: contactForm?.contactSubject,
    contactMessage: contactForm?.contactMessage,
    contactIDProperty: contactForm?.contactIDProperty,
  });
  /* -----------VARS------------ */
  const {
    IDProperty,
    img,
    C_nbBedroom,
    C_nbBathroom,
    C_surfaceBien,
    C_nbParking,
    propertyPointsForts,
    propertyEquipments,
    propertyServices,
    statut,
    familyName,
    categories,
    CodeInterne,
    areaName,
    PrixEuros,
    Denomination,
    paysName,
    DescriptionCourte_fr,
    DescriptionLongue_fr,
    CAHT,
    effectifs,
    tagBgColor,
    tagName,
    tagTagSide,
    tagTextColor,
    EBE,
    RBE,
    DateModification,
    nbCouverts,
    Slug,
    Map_longitude,
    Map_Latitude,
    Map_Zoom,
    Map_Rayon
  } = propDetail;
  let publicUrl = env.publicURL;
  let tagSide = tagTagSide ? tagTagSide : '';
  const recaptchaRef = React.createRef();
  const { TextArea } = Input;
  const dispatch = useDispatch();
  let imagealt = "image";
  const synthese = {
    statut: statut ? statut : "",
    /*Famille: familyName ? familyName : "--",*/
    Catégories: categories ? categories : "",
    "Code interne": CodeInterne ? CodeInterne : "",
    "Secteur géographique": areaName ? areaName : "",
    Pays: paysName ? paysName : "",
  };
  const jourContact = [
    { value: "Lundi", label: "Lundi" },
    { value: "Mardi", label: "Mardi" },
    { value: "Mercredi", label: "Mercredi" },
    { value: "Jeudi", label: "Jeudi" },
    { value: "Vendredi", label: "Vendredi" },
    { value: "Peu Importe", label: "Peu Importe" },
    { value: "Je préfère être contacté par mail", label: "Je préfère être contacté par mail" },
  ];
  const heureContact = [
    { value: "Entre 9H et 12H", label: "Entre 9H et 12H" },
    { value: "Entre 12H et 14H", label: "Entre 12H et 14H" },
    { value: "Entre 14H et 17H", label: "Entre 14H et 17H" },
    { value: "Après 17H", label: "Après 17H" },
  ];
  /* -----------Effects------------ */
  useEffect(() => {}, [contactForm]);

  const getCorrectValue = (value, fallback) => {
    return [undefined, "undefined", null, "null", 0, "0"].includes(value) ? fallback : value
  }

  let formatedCAHT =
    parseFloat(CAHT)
      .toLocaleString("en-US", { useGrouping: true })
      .replace(/,/g, " ") + "€";
  let formatedEBE =
    parseFloat(EBE)
      .toLocaleString("en-US", { useGrouping: true })
      .replace(/,/g, " ") + "€";
  let formatedRBE =
    parseFloat(RBE)
      .toLocaleString("en-US", { useGrouping: true })
      .replace(/,/g, " ") + "€";

  /* -----------Functions------------ */
  const onFormChange_handler = (name, e) => {
    let form = _.cloneDeep(contactForm);
    if (name === "token") {
      if (e) {
        dispatch(
          setContactForm({
            ...form,
            [name]: e,
          })
        );
      } else {
        delete form[name];
        dispatch(setContactForm({ ...form }));
      }
    } else {
      if (
        [
          "contactName",
          "contactMail",
          "contactPhone",
          "contactMessage",
        ].includes(name)
      ) {
        let {
          target: { value },
        } = e;
        dispatch(setContactForm({ ...form, [name]: value }));
      } else {
        dispatch(setContactForm({ ...form, [name]: e }));
      }
    }
  };
  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  const sendMessage_handler = (e) => {
    e.preventDefault();

    let payload = _.cloneDeep(contactForm);
    payload.mailIdObject = IDProperty;
    payload.mailObject = "property";
    payload.propertyName = Denomination;
    payload.propertyRef = CodeInterne;

    if (
      contactForm.contactName &&
      contactForm.contactMail &&
      ValidateEmail(contactForm.contactMail) &&
      contactForm.token
    ) {
      setContactForm("contactIDProperty", IDProperty);
      console.log(payload);
      dispatch(sendMailDmdeInfoAPI(payload));
    } else {
      NotificationComponent(
        true,
        "error",
        "Echec de l'envoi du formulaire",
        contactForm.contactName,
        contactForm.contactMail,
        ValidateEmail(contactForm.contactMail),
        contactForm.token
      );
    }
  };

  return (
    <div className={"property-details-area"}>
      <div className='bg-gray pd-top-100'>
        <div className='container'>
          <div className='row '>
            <div className='col-xl-8 col-lg-8' style={{paddingLeft: 0}}>
              <div className='property-details-slider'>
                <div className='item'>
                  <div className='thumb'>
                    <Carousel autoplay>
                      {(img ? img : []).map((item) => (
                        <img src={item?.imgSrc} alt={item?.imgAlt} />
                      ))}
                    </Carousel>
                  </div>
                </div>
              </div>
              {tagSide &&
                  <div className={tagSide + " ribbon"}><span style={{background: tagBgColor, color: tagTextColor}}>{tagName}</span></div>
              }
              <div className='property-details-slider-info mb-4'>
                <h3 className={"m-0"}>{Denomination}</h3>
                <h4 style={{ fontWeight: "600" }} className={"text-yellow"}>
                  {parseFloat(PrixEuros)
                    .toLocaleString("en-US", { useGrouping: true })
                    .replace(/,/g, " ") + " €"}
                </h4>
                <p style={{ opacity: ".6" }}>
                  <em>
                    Réactualisé le{" "}
                    <Moment format='DD/MM/YYYY' date={DateModification} />
                  </em>
                </p>
              </div>
              <div className='property-info mb-5'>
                {appVersion.version === "cfc" ? (
                  <div className='row'>
                    {CAHT ? (
                      <div className='col-md-3 col-sm-6'>
                        <div className='single-property-info'>
                          <h5>Chiffre d'Affaires</h5>
                          <p>
                            <i className='fa fa-chart-line text-yellow' />
                            {formatedCAHT}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {EBE ? (
                      <div className='col-md-3 col-sm-6'>
                        <div className='single-property-info'>
                          <h5>EBE Retraité *</h5>
                          <p>
                            <i className='fa fa-chart-pie text-yellow' />
                            {formatedEBE}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {RBE ? (
                      <div className='col-md-3 col-sm-6'>
                        <div className='single-property-info'>
                          <h5>RBE</h5>
                          <p>
                            <i className='fa fa-calculator text-yellow' />
                            {formatedRBE}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {effectifs ? (
                      <div className='col-md-3 col-sm-6'>
                        <div className='single-property-info'>
                          <h5>Effectif</h5>
                          <p>
                            <i className='fa fa-users text-yellow' />
                            {effectifs}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {C_surfaceBien ? (
                      <div className='col-md-3 col-sm-6'>
                        <div className='single-property-info'>
                          <h5>Surface</h5>
                          <p>
                            <i className='fa fa-square text-yellow' />
                            {C_surfaceBien}m&sup2;
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {nbCouverts ? (
                      <div className='col-md-3 col-sm-6'>
                        <div className='single-property-info'>
                          <h5>Couverts</h5>
                          <p>
                            <i className='fa fa-utensils text-yellow' />
                            {nbCouverts}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {C_nbParking ? (
                      <div className='col-md-3 col-sm-6'>
                        <div className='single-property-info'>
                          <h5>Parking</h5>
                          <p>
                            <i className='fa fa-car text-yellow' />
                            {C_nbParking}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className='row'>
                    <div className='col-md-3 col-sm-6'>
                      <div className='single-property-info'>
                        <h5>Bedrooms</h5>
                        <p>
                          <i className='fa fa-bed text-yellow' />
                          {C_nbBedroom ? C_nbBedroom : "-"}
                        </p>
                      </div>
                    </div>
                    <div className='col-md-3 col-sm-6'>
                      <div className='single-property-info'>
                        <h5>Bathrooms</h5>
                        <p>
                          <i className='fa fa-bath text-yellow' />
                          {C_nbBathroom ? C_nbBathroom : "-"}
                        </p>
                      </div>
                    </div>
                    <div className='col-md-3 col-sm-6'>
                      <div className='single-property-info'>
                        <h5>Area</h5>
                        <p>
                          <img
                            src={publicUrl + "/assets/img/icons/7.png"}
                            alt={imagealt}
                          />
                          {C_surfaceBien ? C_surfaceBien : "-"} sq. ft.
                        </p>
                      </div>
                    </div>
                    <div className='col-md-3 col-sm-6'>
                      <div className='single-property-info'>
                        <h5>Parking</h5>
                        <p>
                          <i className='fa fa-car text-yellow' />
                          {C_nbParking ? C_nbParking : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='property-news-single-card style-two border-bottom-yellow'>
                <h4 className={"mb-1"}>Description de l’offre</h4>
                <p>
                  <strong>{DescriptionCourte_fr}</strong>
                </p>
                <p>{nl2br(DescriptionLongue_fr)}</p>
              </div>
              <div className='property-news-single-card border-bottom-yellow'>
                <h4>Informations pratiques</h4>
                <div className='row'>
                  {!propertyPointsForts &&
                  !propertyServices &&
                  !propertyEquipments ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : (
                    <>
                      {ObjetToArr(propertyPointsForts).map((item) => (
                        <div className='col-sm-4'>
                          <i className='fa fa-check text-yellow' />{" "}
                          {item.strengthsName}
                        </div>
                      ))}
                      {ObjetToArr(propertyServices).map((item) => (
                        <div className='col-sm-4'>
                          <i className='fa fa-check text-yellow' />{" "}
                          {item.servicesName}
                        </div>
                      ))}
                      {ObjetToArr(propertyEquipments).map((item) => (
                        <div className='col-sm-4'>
                          <i className='fa fa-check text-yellow' />{" "}
                          {item.equipementName}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              {Map_Zoom > 0 ? (
              <div className='property-news-single-card border-bottom-yellow pb-3'>
                <h4>Localisation</h4>
                <div className="leaflet-parent">
                  <MapContainer center={[getCorrectValue(Map_Latitude, 48.856614), getCorrectValue(Map_longitude, 2.3522219)]} zoom={getCorrectValue(Map_Zoom, 13)} scrollWheelZoom={false}>
                    <TileLayer
                      attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                    />
                    {Map_Rayon > 0 ? (
                      <Circle center={[getCorrectValue(Map_Latitude, 48.856614), getCorrectValue(Map_longitude, 2.3522219)]} pathOptions={{fillColor: "#FF0000", color: "none", fillOpacity: "0.6" }} radius={getCorrectValue(Map_Rayon, 400)} />
                    ) : 
                      <Marker position={[getCorrectValue(Map_Latitude, 48.856614), getCorrectValue(Map_longitude, 2.3522219)]} />
                    }
                  </MapContainer>
                </div>
              </div>
              ) : null}
              <div className='property-news-single-card border-bottom-yellow pb-3'>
                <h4>Synthèse</h4>
                <div>
                  {Object.keys(synthese).map((item) => {
                    return <span>{synthese[item] + " "}</span>;
                  })}
                </div>
              </div>
              <div
                className='property-news-single-card pb-3'
                style={{ marginBottom: "0px" }}
              >
                <h4>
                  Prix de vente{" "}
                  <span className={"text-yellow"}>
                    {parseFloat(PrixEuros)
                      .toLocaleString("en-US", { useGrouping: true })
                      .replace(/,/g, " ") + " €"}
                  </span>
                </h4>
                <div>
                  <h3></h3>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4'>
              <form
                className='widget widget-owner-info contact-form-wrap'
                style={{
                  padding: "0",
                  background: "transparent",
                  boxShadow: "none",
                }}
              >
                <div className='contact'>
                  <h4>Cette offre m'intéresse</h4>
                  <div className='rld-single-input'>
                    <input
                      type='text'
                      placeholder='Nom et prénom'
                      onChange={(e) => {
                        onFormChange_handler("contactName", e);
                      }}
                      value={form?.contactName}
                    />
                  </div>
                  <div className='rld-single-input'>
                    <input
                      id='contactMail'
                      type='text'
                      placeholder='Adresse mail'
                      onChange={(e) => {
                        onFormChange_handler("contactMail", e);
                      }}
                    />
                  </div>
                  <div className='rld-single-select'>
                    <Select
                      style={{ fontSize: "12px" }}
                      placeholder='Je souhaite être appelé le...'
                      bordered={false}
                      onChange={(e) => {
                        onFormChange_handler("contactJour", e);
                      }}
                      options={jourContact}
                      allowClear={true}
                    />
                  </div>
                  <div className='rld-single-select'>
                    <Select
                      placeholder='Je préfère être appelé...'
                      bordered={false}
                      onChange={(e) => {
                        onFormChange_handler("contactHoraire", e);
                      }}
                      options={heureContact}
                      allowClear={true}
                    />
                  </div>
                  <div className='rld-single-input'>
                    <input
                      type='text'
                      placeholder='Téléphone'
                      onChange={(e) => {
                        onFormChange_handler("contactPhone", e);
                      }}
                    />
                  </div>
                  <div className='rld-single-select'>
                    <Select
                      placeholder='Je suis...'
                      bordered={false}
                      allowClear={true}
                      onChange={(e) => {
                        onFormChange_handler("contactSubject", e);
                      }}
                      options={[
                        { value: "Je suis vendeur", label: "Je suis vendeur" },
                        {
                          value: "Je suis acheteur",
                          label: "Je suis acheteur",
                        },
                        { value: "Autre", label: "Autre" },
                      ]}
                    />
                  </div>
                  <div className='rld-single-input'>
                    <TextArea
                      rows={4}
                      bordered={false}
                      placeholder='Message'
                      onChange={(e) => {
                        onFormChange_handler("contactMessage", e);
                      }}
                    />
                  </div>
                  <ReCaptchaV2
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY.toString()}
                    onChange={(e) => onFormChange_handler("token", e)}
                  />
                  <div className='btn-wrap text-center'>
                    <button
                      className='btn btn-yellow'
                      onClick={sendMessage_handler}
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
                <div className='contact-info'>
                  <h6 className='mb-3'>Contact Info</h6>
                  <div className='media'>
                    <div className='media-left'>
                      <i className='fa fa-map-marker'></i>
                    </div>
                    <div className='media-body'>
                      <p>Valucian</p>
                      <span>{footerdata.adresse_postale}</span>
                    </div>
                  </div>
                  <div className='media'>
                    <div className='media-left'>
                      <i className='fa fa-phone'></i>
                    </div>
                    <div className='media-body'>
                      <p>Téléphone</p>
                      <span>{footerdata.phone}</span>
                    </div>
                  </div>
                  <div className='media mb-0'>
                    <div className='media-left'>
                      <i className='fa fa-clock-o'></i>
                    </div>
                    <div className='media-body'>
                      <p>Horaires</p>
                      <span>{footerdata.open}</span>
                    </div>
                  </div>
                </div>
                <div className='contact-network'>
                  <h6 className='mb-3'>Partager sur les réseaux sociaux</h6>
                  <div className='row'>
                    <div className='col-lg-2'>
                      <FacebookShareButton
                        quote={statut + " " + Denomination}
                        description={DescriptionCourte_fr}
                        url={publicUrl + "/detail-de-l-offre/" + Slug}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </div>
                    <div className='col-lg-2'>
                      <LinkedinShareButton
                        title={Denomination}
                        url={publicUrl + "/detail-de-l-offre/" + Slug}
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                    </div>
                  </div>
                  {}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gray pd-top-60 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <p
                className={"text-muted"}
                style={{ fontSize: "12px", opacity: 0.75 }}
              >
                * EBE Retraité: L'Excédent Brut d'Exploitation Retraité
                correspond à l'EBE comptable auquel ont été ajoutées les charges
                actuelles qui n'existeront plus lorsque l'affaire aura été cédée
                (rémunération et charges du gérant, dépenses à usage
                personnelle...).
              </p>
            </div>
          </div>
        </div>
      </div>

      <Helmet prioritizeSeoTags>
        <title>{`Valucian - ${Denomination}`}</title>
        <meta name='description' content={DescriptionCourte_fr} />
        <meta
          property='author'
          content='Valucian - Cession de fonds de commerce'
        />
      </Helmet>
    </div>
  );
};

export default PropertyDetails;
