import React, { Component } from 'react';
import sectiondata from '../../data/notre-agence.json';
import parse from 'html-react-parser';

class DreamSection extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.dream

        return <div className="follow-dream-area pd-top-90 pd-bottom-100 bg-gray">
            <div className="container">
                <div className="row">
                    <div className="offset-1 col-lg-6 align-self-center">
                        { data.items.map( ( item, i )=>
                            <div key={ i } className={"single-follow-dream media pd-bottom-20 "+item.class}>
                                <div className="media-left">
                                    <img className="thumb-icon" src={publicUrl + item.image} alt={imagealt}/>
                                </div>
                                <div className="media-body">
                                    <h4>{ item.title }</h4>
                                    <p>{ item.content }</p>
                                </div>
                            </div>
                        ) }
                    </div>
                    <div className="col-lg-5">
                        <div className="thumb mb-4 mb-lg-0">
                            <img src={publicUrl+data.image } alt={ imagealt } />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default DreamSection