import API_ENDPOINT from "../../api/api_endpoint";
import { getApi } from "../../api/api_methods";
import { ObjetToArr } from "../../assets/functions";
import NotificationComponent from "../../components/alert-component/component";
import { loadPropsArea, loadPropsCateg, loadPropsList, loadPropsDetails } from "./index.slice";


export function loadPropsAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_PROPS, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropsList(ObjetToArr(response)))
            })
            .catch((err) => {
                NotificationComponent(false,
                    "error",
                    "Erreur de chargement des données",
                )
            });
    };
}
export function loadPropsAreaAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_PROPS_AREA, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropsArea(response))
            })
            .catch((err) => {
                NotificationComponent(false,
                    "error",
                    "Erreur de chargement des données",
                )
            });
    };
}
export function loadPropsCategAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_PROPS_CATEG, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(loadPropsCateg(response))
            })
            .catch((err) => {
                NotificationComponent(false,
                    "error",
                    "Erreur de chargement des données",
                )
            });
    };
}

export function loadPropDetailsAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_PROPS, payload)
            .then((res) => {
                dispatch(loadPropsDetails(Object.values(res.data)[0]))
            })
            .catch((err) => {
                NotificationComponent(false,
                    "error",
                    "Erreur de chargement des données",
                )
            });
    };
}